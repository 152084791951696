<template>
  <div class="about">
    <b-card class="mt-2" no-body>
      <ValidationObserver ref="about">
        <b-card-body>
          <b-row>
            <b-col cols="12" md="6">
              <ek-text-area
                name="اهدافنا"
                v-model="aboutDto.goals"
                label="اهدافنا"
                placeholder="ادخل نص اهدافنا"
                :rules="[{ type: 'required' }]"
              >
              </ek-text-area>

              <ek-input-image
                noDeleteBtn
                required
                title="انقر لتحميل صورة"
                label="الصورة التابعة لأهدافنا"
                :value="
                  aboutDto.goalsImage.path
                    ? $store.getters['app/domainHost'] + '//' + aboutDto.goalsImage.path
                    : '/media/placeholder/image-placeholder.jpg'
                "
                @input="aboutDto.goalsImage.file = $event"
              ></ek-input-image>
            </b-col>

            <b-col cols="12" md="6">
              <ek-text-area
                name="رؤيتنا"
                v-model="aboutDto.vision"
                label="رؤيتنا "
                placeholder="ادخل نص رؤيتنا"
                :rules="[{ type: 'required' }]"
              >
              </ek-text-area>
              <ek-input-image
                noDeleteBtn
                required
                title="انقر لتحميل صورة"
                label="الصورة التابعة لرؤيتنا"
                :value="
                  aboutDto.visionImage.path
                    ? $store.getters['app/domainHost'] + '//' + aboutDto.visionImage.path
                    : '/media/placeholder/image-placeholder.jpg'
                "
                @input="aboutDto.visionImage.file = $event"
              ></ek-input-image>
            </b-col>
          </b-row>
        </b-card-body>
        <b-card-footer>
          <b-button variant="primary" @click="submitSetAbout"
            >تعديل
            <unicon name="pen" fill="#fff" height="15px"></unicon>
          </b-button>
        </b-card-footer>
      </ValidationObserver>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import EkTextArea from "@/EK-core/components/EK-forms/EK-input-textarea";
import EkInputImage from "@/EK-core/components/EK-forms/EK-input-image";
import { mapState, mapActions } from "vuex";
import { handleDashLangChange } from "@/libs/event-bus";

export default {
  components: {
    EkTextArea,
    EkInputImage,
    ValidationObserver,
  },

  methods: {
    ...mapActions(["getAbout", "setAbout"]),
    submitSetAbout() {
      this.$refs.about.validate().then((suc) => {
        if (suc && this.imagesUploaded) {
          const formData = new FormData();
          formData.append("goals", this.aboutDto.goals);
          formData.append("vision", this.aboutDto.vision);
          formData.append("language", this.aboutDto.language);

          formData.append("visionImage.file", this.aboutDto.visionImage.file);
          formData.append("visionImage.path", this.aboutDto.visionImage.path);

          formData.append("goalsImage.file", this.aboutDto.goalsImage.file);
          formData.append("goalsImage.path", this.aboutDto.goalsImage.path);
          this.setAbout(formData);
        }
      });
    },
  },

  computed: {
    ...mapState({ aboutDto: (state) => state.settings.aboutDto }),
    imagesUploaded() {
      return (
        (!!this.aboutDto.visionImage.file || !!this.aboutDto.visionImage.path) &&
        (!!this.aboutDto.goalsImage.file || !!this.aboutDto.goalsImage.path)
      );
    },
  },

  created() {
    this.getAbout();
    handleDashLangChange(this.getAbout);
  },
};
</script>

<style></style>
